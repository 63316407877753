<script lang="ts">
    import RoundsNav from './RoundsNav.svelte';
    import BannedModal from './BannedModal.svelte';
    import SearchBox from '@/Components/SearchBox.svelte';
    import SuggestionsBox from '@/Components/SuggestionsBox.svelte';
    import ErrorRounds from '../../../../public/animations/error-rounds.json';
    import ValidatingRounds from '../../../../public/animations/validating-rounds.json';
    import CheckMarkRounds from '../../../../public/animations/checkmark-rounds.json';
    import * as DropdownMenu from '$lib/components/ui/dropdown-menu';
    import { onMount } from 'svelte';
    import { Menu } from 'lucide-svelte';
    import { inertia } from '@inertiajs/svelte';
    import axios from 'axios';
    export let suggestions;
    export let roundParticipants;
    export let userRoundScore;
    export let baseScoreToBan;
    export let hasTwitterConnected;
    export let theme;
    export let sidebarSpot;
    export let suggestionSlider;
    export let authUserId: any;
    let verifyDisable = false;
    let retweet = false;

    console.log('Round Participants', roundParticipants);

    // let roundId;

    const configStatus = (status: any) => {
        let config: any = { src: null, size: '', loop: false };

        switch (status) {
            case 'f':
                config = {
                    src: ErrorRounds,
                    size: 'w-6 h-6',
                    loop: false,
                };
                break;
            case 's':
                config = {
                    src: CheckMarkRounds,
                    size: 'w-6 h-6',
                    loop: false,
                };
                break;
            case 'p':
                config = {
                    src: ValidatingRounds,
                    size: 'w-8 h-8',
                    loop: true,
                };
                break;
            default:
                config = null;
        }

        return config;
    };

    const retweetStatus = async (roundId: any) => {
        const response = await axios.get(`/rounds/retweet-statuses/${roundId}/${authUserId}`);

        if (response && response.status === 200) {
            if (response.data.length === 0) {
                retweet = false;
            } else {
                response.data.forEach((tweetStatus: any) => {
                    switch (tweetStatus.status) {
                        case 's':
                            verifyDisable = true;

                            retweet = true;

                            break;

                        case 'f':
                            verifyDisable = true;
                            retweet = false;
                            break;
                        case 'p':
                            verifyDisable = true;

                            retweet = true;
                            break;
                        default:
                            verifyDisable = true;
                    }
                });
            }
            console.log(' Retweet Status', response.data);
        }
    };

    onMount(() => {
        roundParticipants.forEach((participant: any) => {
            retweetStatus(participant.round_id);
            setInterval(() => {
                retweetStatus(participant.round_id);
            }, 10000);
        });
    });

    const verify = async (roundId: any, tweetId: any) => {
        const response = await axios.get(`/rounds/retweet-check/${roundId}/${tweetId}`);

        if (response && response.status === 200) {
            console.log('Verify Resp', response.data);
        }
    };

    const retweetLinkAnimation = () => {
        localStorage.setItem('retweetClicked', 'true');
        verifyDisable = false;
    };
</script>

<div class="container">
    <div class="row">
        {#if userRoundScore <= baseScoreToBan}
            <BannedModal />
        {/if}
        <div class="w-full p-0 md:w-3/5 lg:w-2/3">
            <div class="flex items-center justify-between px-3 pt-3">
                <div>
                    <h5 class="mb-2 font-bold">Rounds</h5>
                    <h6 class="mr-4 mt-2 flex text-muted">Repost eachother and grow together</h6>
                </div>
                <div>
                    <DropdownMenu.Root>
                        {#if hasTwitterConnected}
                            <DropdownMenu.Trigger>
                                <button
                                    type="button"
                                    class="m-0 block px-2 py-1 text-{theme} text-gray-800 hover:text-gray-600 md:hidden {userRoundScore <=
                                    baseScoreToBan
                                        ? 'cursor-not-allowed'
                                        : ''}"
                                    disabled="{userRoundScore <= baseScoreToBan}"
                                >
                                    <Menu />
                                </button>
                            </DropdownMenu.Trigger>
                        {/if}

                        <DropdownMenu.Content>
                            <DropdownMenu.Group>
                                <DropdownMenu.Item>
                                    <a href="{'/rounds'}" use:inertia class="p-2">{'Overview'}</a>
                                </DropdownMenu.Item>
                                <DropdownMenu.Item>
                                    <a href="{'/rounds/my'}" use:inertia class="p-2">{'Rounds participating'}</a>
                                </DropdownMenu.Item>
                                <DropdownMenu.Item>
                                    <a href="{'/rounds/history'}" use:inertia class="p-2">{'Rounds history'}</a>
                                </DropdownMenu.Item>
                            </DropdownMenu.Group>
                        </DropdownMenu.Content>
                    </DropdownMenu.Root>
                </div>
            </div>
            <hr />
            {#if hasTwitterConnected}
                <div class="hidden md:block {userRoundScore <= baseScoreToBan ? 'pointer-events-none blur-sm' : ''}">
                    <RoundsNav />
                </div>
            {/if}
            <div class="m-3 {userRoundScore <= baseScoreToBan ? 'pointer-events-none blur-md' : ''}">
                <div>
                    <table class="min-w-full leading-normal">
                        <caption class="caption-bottom text-center text-xs"> Participation details </caption>
                        <thead>
                            <tr>
                                <th
                                    class="border-b py-3 pl-2 text-center text-xs font-semibold
                                            uppercase tracking-wider"
                                >
                                    User
                                </th>
                                <th
                                    class="border-b py-3 pl-2 text-center text-xs font-semibold uppercase tracking-wider"
                                >
                                    Tweet link
                                </th>
                                <th
                                    class="border-b py-3 pl-2 text-center text-xs font-semibold
                                        uppercase tracking-wider"
                                >
                                    Status
                                </th>
                                <th
                                    class="border-b py-3 pl-2 text-center text-xs font-semibold
                                        uppercase tracking-wider"
                                >
                                </th>
                            </tr>
                        </thead>
                        <tbody>
                            {#each roundParticipants as participant}
                                <tr>
                                    <td class="border-b py-3 pl-2 text-center text-sm">
                                        <div>
                                            <div>{participant.avatar}</div>
                                            <div>{participant.name}</div>
                                        </div>
                                    </td>
                                    <td class="border-b py-3 pl-2 text-center text-sm">
                                        <a
                                            href="https://x.com/intent/retweet?tweet_id={participant.tweet_id}"
                                            target="_blank"
                                            class="{`retweet-link ${retweet ? 'pointer-events-none cursor-not-allowed opacity-30' : ''}`}"
                                            data-user-id="{participant.user_id}"
                                            data-round-id="{participant.round_id}"
                                            data-tweet-id="{participant.tweet_id}"
                                            on:click="{retweetLinkAnimation}"
                                        >
                                            Retweet Here
                                        </a>
                                    </td>

                                    <td class="border-b py-3 pl-2 text-center text-sm">
                                        <div id="status-{participant.user_id}" class="flex justify-center">
                                            {#if configStatus(participant.status)}
                                                <tgs-player
                                                    class="{configStatus(participant.status).size}"
                                                    autoplay
                                                    loop="{configStatus(participant.status).loop}"
                                                    mode="normal"
                                                    src="{configStatus(participant.status).src}"
                                                ></tgs-player>
                                            {/if}
                                        </div>
                                    </td>

                                    <td class="border-b py-3 pl-2 text-center text-sm">
                                        <button
                                            class="mb-0 rounded-xl bg-bmn-500"
                                            data-user-id="{participant.user_id}"
                                            data-round-id="{participant.round_id}"
                                            data-tweet-id="{participant.tweet_id}"
                                            on:click="{() => {
                                                verify(participant.round_id, participant.tweet_id);
                                            }}"
                                            disabled="{verifyDisable}"
                                        >
                                            Verify
                                        </button>
                                    </td>
                                </tr>
                            {/each}
                        </tbody>
                    </table>
                </div>
            </div>
        </div>

        <div class="suggestions-wrapper hidden min-h-screen w-full border-l pb-5 pt-4 md:block md:w-2/5 lg:w-1/3">
            <div class="feed-widgets">
                <div class="mb-4">
                    <SearchBox />
                </div>
                {#if !suggestionSlider}
                    <SuggestionsBox {suggestions} isMobile="{false}" />
                {/if}
                {#if sidebarSpot}
                    <div class="mt-4">
                        {sidebarSpot}
                    </div>
                {/if}
            </div>
        </div>
    </div>
</div>
